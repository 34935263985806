
import {Component, Vue} from 'vue-property-decorator';
import multiSelect from '@/components/multiSelect/index.vue';
import {
  applications,
  applicationRequests,
  payMerchants,
} from '../../../../../resources';
import {ObjectId} from 'bson';
import {Form} from 'element-ui';
import {PayMerchant} from '@/externals/MaxCI-PayMerchant-v1';
import {
  ApplicationServiceConfig,
  RechargeConfig,
} from '@/externals/MaxCI-Application-v1';
interface IdPayMerchant extends PayMerchant {
  id: string;
}
@Component({
  name: 'rechargeService',
  components: {
    multiSelect,
  },
})
export default class extends Vue {
  private serviceConfigs: null | ApplicationServiceConfig = null;
  private wechatPayMerchantData: Array<IdPayMerchant> = []; //微信支付商户
  private formData = {
    wechat: '', //支付商户
    merchantCode: '', //商户code
    clientSecret: '', //商户密钥
  };
  private rules = {
    wechat: [{required: true, message: '请选择支付商户', trigger: 'blur'}],
    merchantCode: [
      {required: true, message: '请输入商户code', trigger: 'blur'},
    ],
    clientSecret: [
      {required: true, message: '请输入商户密钥', trigger: 'blur'},
    ],
  };
  async created() {
    try {
      //查询微信支付商户
      const payMerchant = await payMerchants.find(stage =>
        stage.$match(match =>
          match(
            f => f('spec')('applicationId'),
            e =>
              e.$eq(
                ObjectId.createFromHexString(this.$route.params.applicationId),
              ),
          )(
            f => f('spec')('type'),
            e => e.$eq('微信'),
          ),
        ),
      );
      if (payMerchant.length > 0) {
        this.wechatPayMerchantData = payMerchant.map(v => {
          return {
            ...v,
            id: v._id.toHexString(),
          };
        });
      }
      const application = (
        await applications.find(stage =>
          stage.$match(match => {
            match(
              f => f('_id'),
              e =>
                e.$eq(
                  ObjectId.createFromHexString(
                    this.$route.params.applicationId,
                  ),
                ),
            );
            return match;
          }),
        )
      ).find(() => true);
      this.serviceConfigs = application?.spec.serviceConfigs ?? null;
      if (this.serviceConfigs?.充值?.payMerchants) {
        if (this.serviceConfigs?.充值?.payMerchants.wechat) {
          this.formData.wechat =
            this.serviceConfigs?.充值?.payMerchants.wechat.toHexString() ?? '';
        }
      }
      this.formData.merchantCode =
        this.serviceConfigs?.充值?.merchantCode ?? '';
      this.formData.clientSecret =
        this.serviceConfigs?.充值?.clientSecret ?? '';
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      this.$message.error('网络异常，请稍后重试');
    }
  }
  private submitForm() {
    (this.$refs.formData as Form).validate(valid => {
      if (valid) {
        const rechargeConfig = {
          payMerchants: {
            wechat: ObjectId.createFromHexString(this.formData.wechat),
          },
          merchantCode: this.formData.merchantCode,
          clientSecret: this.formData.clientSecret,
        } as RechargeConfig;
        let serviceConfigs = null as null | ApplicationServiceConfig;
        if (this.serviceConfigs) {
          if (this.serviceConfigs.充值) {
            serviceConfigs = this.serviceConfigs;
            serviceConfigs.充值 = rechargeConfig;
          } else {
            serviceConfigs = {
              ...this.serviceConfigs,
              充值: rechargeConfig,
            };
          }
        } else {
          serviceConfigs = {
            充值: rechargeConfig,
          };
        }
        try {
          applicationRequests
            .create(
              [
                {
                  spec: {
                    type: '更新应用',
                    applicationId: ObjectId.createFromHexString(
                      this.$route.params.applicationId,
                    ),
                    serviceConfigs: serviceConfigs,
                  },
                },
              ],
              {
                watch: {
                  filter: filter =>
                    filter(
                      f => f('operationType'),
                      e => e.$eq('update'),
                    )(
                      f => f('fullDocument')('status')('phase'),
                      e => e.$exists(true),
                    ),
                },
                fullResource: true,
              },
            )
            .then(res => {
              if (res[0].status?.phase === '成功') {
                this.$message.success('保存成功');
              } else {
                this.$message.error(res[0].status?.conditions[0].message ?? '');
              }
            });
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (e: any) {
          this.$message.error('网络异常，请稍后重试');
        }
      } else {
        return false;
      }
    });
  }
}
