
import {Component, Vue} from 'vue-property-decorator';
import Submenu from '@/components/Submenu/index.vue';
import memberList from './components/member/memberList/index.vue';
import memberService from './components/member/memberService/index.vue';
import rechargeList from './components/recharge/rechargeList/index.vue';
import rechargeService from './components/recharge/rechargeService/index.vue';
import {applications} from '../../resources';
import {ObjectId} from 'bson';
@Component({
  name: 'member',
  components: {
    Submenu,
    memberList,
    memberService,
    rechargeList,
    rechargeService,
  },
})
export default class extends Vue {
  private menu = '';
  private submenu: Array<{
    top: string;
    list: Array<{name: string; active: boolean}>;
  }> = [];
  async created() {
    this.$store.state.applicationId = this.$route.params.applicationId;
    const application = (
      await applications.find(stage =>
        stage.$match(match => {
          match(
            f => f('_id'),
            e =>
              e.$eq(
                ObjectId.createFromHexString(this.$route.params.applicationId),
              ),
          );
          return match;
        }),
      )
    ).find(() => true);
    let memberShow = false,
      serviceShow = false;
    if (application?.spec.enableServices) {
      application?.spec.enableServices.forEach(item => {
        if (item === '会员卡') {
          memberShow = true;
        }
        if (item === '充值') {
          serviceShow = true;
        }
      });
    }
    if (memberShow && serviceShow) {
      this.submenu = [
        {
          top: '会员',
          list: [
            {name: '会员卡设置', active: true},
            {name: '会员卡服务', active: false},
          ],
        },
        {
          top: '充值',
          list: [
            {name: '充值设置', active: true},
            {name: '充值服务', active: false},
          ],
        },
      ];
      this.menu = this.$route.query.menu
        ? (this.$route.query.menu as string)
        : '会员卡设置';
    }
    if (memberShow && !serviceShow) {
      this.submenu = [
        {
          top: '会员',
          list: [
            {name: '会员卡设置', active: true},
            {name: '会员卡服务', active: false},
          ],
        },
      ];
      this.menu = this.$route.query.menu
        ? (this.$route.query.menu as string)
        : '会员卡设置';
    }
    if (!memberShow && serviceShow) {
      this.submenu = [
        {
          top: '充值',
          list: [
            {name: '充值设置', active: true},
            {name: '充值服务', active: false},
          ],
        },
      ];
      this.menu = this.$route.query.menu
        ? (this.$route.query.menu as string)
        : '充值设置';
    }
  }
}
