
import {Component, Vue, Watch} from 'vue-property-decorator';
import Pagination from '@/components/Pagination/index.vue';
import {memberCards} from '../../../../../resources';
import {ObjectId} from 'bson';
import {Form} from 'element-ui';
import {MemberCard} from '@/externals/MaxCI-MemberCard-v1';

@Component({
  name: 'memberList',
  components: {
    Pagination,
  },
})
export default class extends Vue {
  private validprice = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    rule: any,
    value: string,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    callback: (error?: Error) => void,
  ) => {
    if (!/(^[1-9]\d*(\.\d{1,2})?$)|(^0(\.\d{1,2})?$)/.test(value)) {
      callback(new Error('价格格式有误'));
    } else {
      if (Number(value) < 0.01) {
        callback(new Error('价格必须大于0.01'));
      } else {
        callback();
      }
    }
  };
  private validOldPrice = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    rule: any,
    value: string,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    callback: (error?: Error) => void,
  ) => {
    if (value === '' || value === null) {
      callback();
    } else {
      if (!/(^[1-9]\d*(\.\d{1,2})?$)|(^0(\.\d{1,2})?$)/.test(value)) {
        callback(new Error('价格格式有误'));
      } else {
        if (Number(value) < 0.01) {
          callback(new Error('价格必须大于0.01'));
        } else {
          callback();
        }
      }
    }
  };
  private dialogDel = false;
  private submitFlag = false; //提交开关
  private title = '';
  private dialogAdd = false;
  private formData = {
    name: '',
    price: null as null | number,
    originalPrice: null as null | number,
    validDate: 365,
  };
  private rules = {
    name: [{required: true, message: '请输入名称', trigger: 'blur'}],
    price: [
      {required: true, message: '请输入现价', trigger: 'blur'},
      {validator: this.validprice, trigger: 'blur'},
    ],
    originalPrice: [{validator: this.validOldPrice, trigger: 'blur'}],
  };
  private listParams = {
    page: 1,
    limit: 10,
  };
  private total = 0;
  private list: Array<MemberCard> = [];
  private applicationId = ObjectId.createFromHexString(
    this.$route.params.applicationId,
  );
  private operateId: null | ObjectId = null;
  async created() {
    this.updateList();
  }
  @Watch('dialogAdd')
  private changeDialogAdd() {
    if (!this.dialogAdd) {
      (this.$refs.formData as Form).resetFields();
      this.formData.validDate = 365;
    }
  }
  //列表
  private async updateList() {
    try {
      const list = await memberCards.find(stage =>
        stage
          .$match(match => {
            match(
              f => f('spec')('applicationId'),
              e => e.$eq(this.applicationId),
            );
            return match;
          })
          .$facet(facet =>
            facet('table', tableStage =>
              tableStage
                .$sort(sort =>
                  sort(f => f('metadata')('creationTimestamp'), '降序'),
                )
                .$skip((this.listParams.page - 1) * this.listParams.limit)
                .$limit(this.listParams.limit),
            )('count', countStage => countStage.$count('count')),
          ),
      );
      this.total = list[0].count[0] ? list[0].count[0].count.valueOf() : 0;
      this.list = list[0].table;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      this.$message.error('网络异常，请稍后重试');
    }
  }
  //删除提示
  private delPopUps(id: ObjectId) {
    this.operateId = id;
    this.dialogDel = true;
  }
  //删除
  private async delSumbit() {
    try {
      if (this.operateId) {
        const memberCard = await memberCards.delete(filter =>
          filter(
            f => f('_id'),
            e => e.$eq(this.operateId as ObjectId),
          )(
            f => f('spec')('applicationId'),
            e => e.$eq(this.applicationId),
          ),
        );
        if (memberCard) {
          this.dialogDel = false;
          this.operateId = null;
          this.$message.success('已删除');
          this.updateList();
        }
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      this.$message.error('网络异常，请稍后重试');
    }
  }
  //编辑弹窗
  private editPopUps(item: MemberCard) {
    this.dialogAdd = true;
    this.title = '编辑';
    this.formData.name = item.spec.name;
    this.formData.price = item.spec.price / 100;
    this.formData.originalPrice = item.spec.originalPrice
      ? item.spec.originalPrice / 100
      : null;
    this.formData.validDate = item.spec.validDate;
    this.operateId = item._id;
  }
  //添加弹窗
  private addPopUps() {
    this.dialogAdd = true;
    this.title = '新增';
  }
  //添加保存
  private addSumbit() {
    (this.$refs.formData as Form).validate(valid => {
      if (valid) {
        try {
          if (this.title === '新增') {
            memberCards
              .create([
                {
                  spec: {
                    name: this.formData.name,
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    price: Math.round(Number(this.formData.price!) * 100),
                    ...(this.formData.originalPrice !== null
                      ? {
                          originalPrice: Math.round(
                            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                            Number(this.formData.originalPrice!) * 100,
                          ),
                        }
                      : {}),
                    validDate: this.formData.validDate,
                    applicationId: this.applicationId,
                  },
                },
              ])
              .then(res => {
                if (res.length > 0) {
                  this.$message.success('保存成功');
                  this.dialogAdd = false;
                  this.updateList();
                }
              });
          } else {
            memberCards
              .update(
                filter =>
                  filter(
                    f => f('_id'),
                    e => e.$eq(this.operateId as ObjectId),
                  )(
                    f => f('spec')('applicationId'),
                    e => e.$eq(this.applicationId),
                  ),
                update => {
                  update.$set(s => {
                    s(f => f('spec')('name'), this.formData.name)(
                      f => f('spec')('price'),
                      Math.round(Number(this.formData.price) * 100),
                    )(f => f('spec')('name'), this.formData.name)(
                      f => f('spec')('validDate'),
                      this.formData.validDate,
                    );
                    if (
                      this.formData.originalPrice ||
                      this.formData.originalPrice === 0
                    ) {
                      s(
                        f => f('spec')('originalPrice'),
                        Math.round(Number(this.formData.originalPrice) * 100),
                      );
                    }
                    return s;
                  });
                  if (
                    !this.formData.originalPrice &&
                    this.formData.originalPrice !== 0
                  ) {
                    update.$unset(s => {
                      s(f => f('spec')('originalPrice'), '');
                      return s;
                    });
                  }
                  return update;
                },
              )
              .then(res => {
                if (res.length > 0) {
                  this.$message.success('修改成功');
                  this.dialogAdd = false;
                  this.updateList();
                }
              });
          }
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (e: any) {
          this.$message.error('网络异常，请稍后重试');
        } finally {
          this.submitFlag = false;
        }
      } else {
        return false;
      }
    });
  }
}
